import React, { useEffect, useReducer, useState } from "react";
import { CarSubmission } from "@carfax/web-ui/components/CarSubmission";
import { useRouter } from "next/router";
import { useStores } from "hooks";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import { RecordCheckErrorMessages } from "utils/pricing-response/RecordCheckErrorMessages";
import { useTranslation } from "app";
import { linkUtil } from "utils/i18n/link-util";
import { colors } from "@carfax/web-ui/core";
import ContentLoading from "components/Layout/ContentLoading";
import VinSearchComponent from "./components/VinSearchComponent";
import { additionalContentReducer } from "./additionalContentReducer";
import { dataLayerEvents, EventActions, EventCategories } from "domain/SeoAndAnalytics/legacy/GoogleAnalytics/utils";
import { Image } from "contentful/types/content-type";
import RecaptchaActions from "domain/Recaptcha/actions";
import { LanguageResolver } from "../../../i18n/language-resolver";
import AlertService from "components/Layout/Alerts/utils/alert-service";

interface Props {
  module: {
    title: string;
    partnerLogo: Image;
    enterVinMessage: string;
    enterWithoutVinMessage: string;
    titleWithoutVin: string;
    afterVinCheck: string;
  };
}

const PartnerHeader: React.FC<Props> = ({ module }) => {
  const { t } = useTranslation("pages-record-check");
  const { reportPricingStore, recordCheckStore, checkoutStore, layoutStore } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const routes = useRoutes();
  const { toggles } = layoutStore;
  const language = LanguageResolver.getCurrentLanguageShort();

  const router = useRouter();
  const {
    pic: picture,
    make,
    vin,
    plate_encoded,
    adurl,
  } = router.query as {
    pic?: string;
    make?: string;
    vin?: string;
    plate_encoded?: string;
    adurl?: string;
  };
  const queryContainsVinOrPlateEncoded = vin || plate_encoded;
  checkoutStore.setPartnerBackUrl(adurl);

  const initialAdditionalContent = {
    showContent: false,
    isVinValid: false,
    totalRecords: 0,
    make,
    vhrAlreadyBought: false,
  };
  const [additionalContent, dispatch] = useReducer(additionalContentReducer, initialAdditionalContent);

  const handleErrorPricingResponse = (e, vinQuery) => {
    if (e === RecordCheckErrorMessages.REPORT_ALREADY_BOUGHT) {
      dispatch({ type: "updateVhrAlreadyBought" });
      dispatch({ type: "updateEncryptedVin", encryptedVin: vinQuery });
      AlertService.showAlertToTranslate("vinSearch.errors.vinReportAlreadyBought.description", "success");
    }
    if (e != RecordCheckErrorMessages.RECAPTCHA) {
      setIsLoading(false);
      dispatch({ type: "vinValidation", isVinValid: false });
      dispatch({ type: "showContent" });
      dataLayerEvents.submitGtagEvent(EventActions.ERROR, EventCategories.SEARCH_VIN, vin);
    }
  };

  /**
   * The encoded vinreg comes to CARFAX as a URL which is base64 encoded. Base64 allows slash character which has a special
   * meaning when calling other REST services - a resource separator. Thus, before sending it further, it must be changed to something else
   * (underscore in this case) and later converted back, otherwise it can not be used as a path parameter.
   *
   * @param vinregEncoded
   */
  function preParse(vinregEncoded: string): string {
    const SLASH = "/";
    const UNDERSCORE = "_";

    return vinregEncoded.replace(SLASH, UNDERSCORE);
  }

  const handlePricingResponse = async (vinQuery, plateEncodedQuery) => {
    setIsLoading(true);
    const PricingApi = (await import("utils/pricing-response/pricing-api")).default;
    try {
      const data = plateEncodedQuery
        ? await PricingApi.fetchPricingResponseForEncryptedVin(preParse(plateEncodedQuery), RecaptchaActions.partner)
        : await PricingApi.fetchPricingResponseForVin(vinQuery, RecaptchaActions.partner);

      reportPricingStore.setDataFromApi(data);
      recordCheckStore.setDataFromApi({ ...data, encryptedQuery: plateEncodedQuery });
      checkoutStore.setDataFromApi(data);

      const totalRecords = recordCheckStore.reportDataTotal;
      const { makeAndModel } = recordCheckStore;

      dispatch({ type: "updateEncryptedVin", encryptedVin: data.recordCheck.query });
      dispatch({ type: "updateMake", make: makeAndModel });
      dispatch({ type: "setTotalRecords", totalRecords });
      setIsLoading(false);
      dispatch({ type: "vinValidation", isVinValid: true });
      dispatch({ type: "showContent" });
      dataLayerEvents.submitGtagEvent(EventActions.SUCCESS, EventCategories.SEARCH_VIN, vin);
    } catch (error) {
      handleErrorPricingResponse(error, vinQuery);
    }
  };

  const handleShowingContent = () => {
    if (queryContainsVinOrPlateEncoded) {
      handlePricingResponse(vin, plate_encoded);
    } else {
      dispatch({ type: "showContent" });
    }
  };

  useEffect(() => {
    handleShowingContent();
  }, []);

  const carSubmissionPropsForValidVin = {
    title: module.title,
    enterVinMessage: module.enterVinMessage,
    details: [
      {
        name: t("partner.partnerCarSubmission.make"),
        value: additionalContent.make,
      },
      {
        name: t("partner.partnerCarSubmission.vin"),
        value: additionalContent.encryptedVin,
      },
    ],
  };

  const carSubmissionPropsForInvalidVin = {
    title: module.titleWithoutVin,
    enterVinMessage: module.enterWithoutVinMessage,
    details: [
      {
        name: t("partner.partnerCarSubmission.make"),
        value: additionalContent.make,
      },
    ],
  };

  const getPreviewLink = () => {
    if (toggles["reportPreviewPage" + language.toUpperCase()]) {
      return linkUtil.i18nLink(routes.reportPreview);
    } else {
      return linkUtil.i18nLink(routes.preview);
    }
  };

  const carSubmissionPropsCommon = {
    partnerLogo: module.partnerLogo?.url,
    carImage: picture,
    afterVinCheck: module.afterVinCheck,
    vhrFound: t("preview.makeAndModel.headline", { count: additionalContent.totalRecords }),
    isVinValid: additionalContent.isVinValid,
    ctaLink: getPreviewLink(),
    ctaText: t("partner.partnerCarSubmission.linkToPreview"),
    VinSearchComponent: additionalContent.vhrAlreadyBought ? (
      <VinSearchComponent
        errorMsgOnLoad={RecordCheckErrorMessages.REPORT_ALREADY_BOUGHT}
        vinOnLoad={additionalContent.encryptedVin}
      />
    ) : (
      <VinSearchComponent />
    ),
    showContent: additionalContent.showContent,
  };

  const carSubmissionProps = additionalContent.isVinValid
    ? { ...carSubmissionPropsCommon, ...carSubmissionPropsForValidVin }
    : { ...carSubmissionPropsCommon, ...carSubmissionPropsForInvalidVin };
  return (
    <CarSubmission {...carSubmissionProps}>{isLoading && <ContentLoading color={colors.black.hex} />}</CarSubmission>
  );
};

export default PartnerHeader;
