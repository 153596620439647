import {
  ModuleBanner,
  ModuleCampaignBanner,
  ModuleDisclaimer,
  ModuleLandingPageVoucherEntry,
  PageModular,
  PageModularModuleWrapper,
} from "contentful/PageModularModule";
import { ModuleContentType } from "contentful/types/content-type";
import { useDataLayer } from "domain/SeoAndAnalytics/legacy/GoogleAnalytics/hooks/useDataLayer";
import Seo from "domain/SeoAndAnalytics/Seo";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import CampaignBanner from "./CampaignBanner";
import { ModularPageComponent, ModulesWithTheme } from "@carfax/web-ui/components/ModulesWithTheme";
import ModularPageComponentWrapper from "./ModularPageComponentWrapper";
import { ThemeName } from "@carfax/web-ui/components/ModulesWithTheme/utils/useTheme";
import { isEven } from "utils/integer";
import { useTranslation } from "../../app";
import LandingPageVoucherEntry from "./LandingPageVoucherEntry";

const PromotionBanner = dynamic(() => import("./PromotionBanner"));
const LegalDisclaimer = dynamic(() => import("./LegalDisclaimer"));

const fixedModules = [
  ModuleContentType.ModuleBanner,
  ModuleContentType.ModuleDisclaimer,
  ModuleContentType.ModuleCampaignBanner,
  ModuleContentType.ModuleLandingPageVoucherEntry,
];

export interface ModularPageProps {
  modularPage: PageModular;
  keyString?: string;
  pageTypeDataLayer?: string;
}

const getFixedModule = (wrappers: PageModularModuleWrapper[], type: ModuleContentType) => {
  return wrappers.find((moduleWrapper) => moduleWrapper.contentType === type)?.module;
};

const getContentModuleWrappers = (wrappers: PageModularModuleWrapper[]): PageModularModuleWrapper[] => {
  return wrappers.filter((moduleWrapper) => !fixedModules.includes(moduleWrapper.contentType));
};

export const ModularPage: React.FC<ModularPageProps> = (props) => {
  const { t } = useTranslation(["page-pricing"]);
  const { modularPage, keyString = "modular-page-module-wrapper", pageTypeDataLayer = "Modular Page" } = props;

  const promotionBannerModule = useMemo(
    () => getFixedModule(modularPage.modules, ModuleContentType.ModuleBanner),
    [modularPage.modules]
  ) as ModuleBanner;

  const disclaimerModule = useMemo(
    () => getFixedModule(modularPage.modules, ModuleContentType.ModuleDisclaimer),
    [modularPage.modules]
  ) as ModuleDisclaimer;

  const campaignBannerModule = useMemo(
    () => getFixedModule(modularPage.modules, ModuleContentType.ModuleCampaignBanner),
    [modularPage.modules]
  ) as ModuleCampaignBanner;

  const voucherLandingPageBannerModule = useMemo(
    () => getFixedModule(modularPage.modules, ModuleContentType.ModuleLandingPageVoucherEntry),
    [modularPage.modules]
  ) as ModuleLandingPageVoucherEntry;

  const contentModuleWrappers = useMemo(() => getContentModuleWrappers(modularPage.modules), [modularPage.modules]);

  const seoConfig = modularPage?.seo;

  const dataLayer = useDataLayer(seoConfig?.title, pageTypeDataLayer);

  const modularPageTheme = modularPage?.theme || ThemeName.DEFAULT;

  return (
    <>
      <Seo
        dataLayer={dataLayer}
        title={seoConfig?.title}
        description={seoConfig?.description}
        keywords={seoConfig?.keywords}
        canonical={seoConfig?.canonical}
        robotsMeta={seoConfig?.robotsMeta}
        hrefLang={seoConfig?.hrefLang}
        image={seoConfig?.image?.fields?.file?.url}
        schema={{
          product: {
            priceCurrency: t("seoMarkupSchema.priceCurrency"),
            packages: t("pricingCard", { returnObjects: true }),
            image: t("seoMarkupSchema.imageUrl"),
          },
          article: {
            datePublished: modularPage.createdAt?.slice(0, 10),
            headline: seoConfig?.title,
            description: seoConfig?.description,
          },
        }}
      />
      {promotionBannerModule && <PromotionBanner module={promotionBannerModule} />}
      {campaignBannerModule && <CampaignBanner module={campaignBannerModule} />}
      {voucherLandingPageBannerModule && <LandingPageVoucherEntry module={voucherLandingPageBannerModule} />}

      {contentModuleWrappers && (
        <ModulesWithTheme themeName={modularPageTheme}>
          {contentModuleWrappers.map((moduleWrapped, index: number) => {
            return (
              <ModularPageComponent key={`${keyString}-${index}`}>
                <ModularPageComponentWrapper moduleWrapper={moduleWrapped} splitterEnabled={isEven(index)} />
              </ModularPageComponent>
            );
          })}
        </ModulesWithTheme>
      )}

      {disclaimerModule && <LegalDisclaimer module={disclaimerModule} />}
    </>
  );
};
